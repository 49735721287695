.logo { max-width: 200px; }
.sticky-nav.nav-border-bottom { background-color: #FFF; }
@media (min-width: 992px) {
    .navbar-right .dibujo {
        right: 185px;

    }
    .navbar-right .dropdown-menu-left {
        right: auto;
        left: 0;
    }
}
@media (min-width: 992px) {
    .navbar-right .instalaciones {
        right: -210px;

    }
    .navbar-right .dropdown-menu-left {
        right: auto;
        left: 0;
    }
}
@media (min-width: 992px) {
    .navbar-right .curriculum {
        right: -305px;

    }
    .navbar-right .dropdown-menu-left {
        right: auto;
        left: 0;
    }
}



@media (min-width: 1200px) {
    .navbar-right .dibujo {
        right: 205px;

    }
    .navbar-right .dropdown-menu-left {
        right: auto;
        left: 0;
    }
}
@media (min-width: 1200px) {
    .navbar-right .instalaciones {
        right: -195px;

    }
    .navbar-right .dropdown-menu-left {
        right: auto;
        left: 0;
    }
}
@media (min-width: 1200px) {
    .navbar-right .curriculum {
        right: -295px;

    }
    .navbar-right .dropdown-menu-left {
        right: auto;
        left: 0;
    }
}
.margin-three {
  margin: 5px 0px 5px !important;
}
@media (min-width: 1200px) {
    .st-footer {
      margin:-40px 0px 0px 25px !important;
    }
}


@media (min-width: 992px) {
    .st-footer {
      margin:-40px 0px 0px -90px;
    }
}


@media (min-width: 768px) {
    .st-footer {
      margin-top: -40px;
    }
}

.st-dibujo {
background-image: url('/img/bg-dibujo.jpg');
}
.st-h3 {
  color: #FFF;
  letter-spacing: normal;
  text-transform: inherit;
  line-height: 25px;
  font-size: 17px;
}
.st-h1 {
  color: #FFF;
  letter-spacing: normal;
  text-transform: uppercase;
  line-height: 25px;
  font-size: 40px;
}

.bg-yellow {
    background: #FFF !important;
}
.st-instalaciones {
    background-image: url('/img/bg-instalaciones.jpg');
}
.st-curriculum {
    background-image: url('/img/bg-curriculum.jpg');
    height: 328px;
}
.bg-black {
    background: #000000;
}
.st-p {
    font-size: 15px;
    color: #FFF;
    text-transform: capitalize;
    margin-top: -40px;
}
.st-fichas{
    height: 160px;
}
.st-fichasdibujo-bg{
    background-image: url('/img/bg-dibujo.jpg');
    height: 193px;
}
.st-fichasinstalaciones-bg{
    background-image: url('/img/bg-instalaciones.jpg');
    height: 193px;
}
.st-txt-fichas{
    display: table;
    height: 100%;
    width: 100%;
    margin-top: 96px;
}
.st-txt-contacto{
    display: table;
    height: 100%;
    width: 100%;
    margin-top: 150px;
}
.st-ficha-margin{
    margin-bottom: 120px;
}
