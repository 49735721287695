$prefix: "st/";
@import 'st/vars';
@import 'st/animate';
@import 'st/et-line-icons';
@import 'st/extralayers';
@import 'st/settings';
@import 'st/magnific-popup';
@import 'st/owl.carousel';
@import 'st/owl.transitions';
@import 'st/full-slider';
@import 'st/text-effect';
@import 'st/menu-hamburger';
@import 'st/style';
@import 'st/responsive';
body{
  @include st-page-body(arial);
  @include st-page-pos;
  @include st-page-size;
  @include st-page-clear;
  @include st-page-border;
  @include st-txt-all;
  @include st-txt-size;
  @include st-txt-align;
  @include st-txt-all;
}
