// Paths
$path-font: "/fonts/";
$path-img: "/img/";

// Palette
$brand: #01a664;
$brand-hov: darken($brand, 5%);
$brand-dark: darken($brand-hov, 5%);
$brand2: #b1c966;
$brand2-hov: darken($brand2, 10%);
$brand2-dark: darken($brand2-hov, 10%);
$brand3: #dc8718;
$brand3-hov: darken($brand3, 10%);
$brand3-dark: darken($brand3-hov, 10%);
$color-txt: #333333;
$color-txt2: #ffffff;
$color-border: #aaaaaa;
$color-bg: #ffffff;

//$grid-float-breakpoint: $screen-md-min;

// Tamaños
// Extra small screen / phone
//** Deprecated `@screen-xs` as of v3.0.1
$screen-xs:                  480px;
//** Deprecated `@screen-xs-min` as of v3.2.0
$screen-xs-min:              $screen-xs;
//** Deprecated `$screen-phone` as of v3.0.1
$screen-phone:               $screen-xs-min;

// Small screen / tablet
//** Deprecated `$screen-sm` as of v3.0.1
$screen-sm:                  768px;
$screen-sm-min:              $screen-sm;
//** Deprecated `$screen-tablet` as of v3.0.1
$screen-tablet:              $screen-sm-min;

// Medium screen / desktop
//** Deprecated `$screen-md` as of v3.0.1
$screen-md:                  992px;
$screen-md-min:              $screen-md;
//** Deprecated `$screen-desktop` as of v3.0.1
$screen-desktop:             $screen-md-min;

// Large screen / wide desktop
//** Deprecated `$screen-lg` as of v3.0.1
$screen-lg:                  1200px;
$screen-lg-min:              $screen-lg;
//** Deprecated `$screen-lg-desktop` as of v3.0.1
$screen-lg-desktop:          $screen-lg-min;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max:              ($screen-sm-min - 1);
$screen-sm-max:              ($screen-md-min - 1);
$screen-md-max:              ($screen-lg-min - 1);

// Functions
@mixin st-page-body($font: "'Roboto', sans-serif") {
    font-family: $font;
    cursor: default;
}
@mixin st-page-bg($img: 'none', $col: 'transparent', $rep: 'no-repeat', $size: 'auto', $pos: 'initial') {
        background-image: $img;
        background-color: $col;
        background-repeat: $rep;
        background-size: $size;
        background-position: $pos;
    }
@mixin st-page-pos($pos: 'static', $zind: 'initial', $t: 'auto', $r: 'auto', $b: 'auto', $l: 'auto') {
        position: $pos;
        z-index: $zind;
        top: $t;
        right: $r;
        bottom: $b;
        left: $l;
    }
@mixin st-page-size($w: 'auto', $h: 'auto', $mar: 'auto', $pad: 'initial') {
        width: $w;
        height: $h;
        margin: $mar;
        padding: $pad;
    }
@mixin st-page-clear($cl: 'none', $ov: 'visible') {
        clear: $cl;
        overflow: $ov;
    }
@mixin st-page-border($t: '0', $r: '0', $b: '0', $l: '0', $radius: '0') {
        border-top: $t;
        border-right: $r;
        border-bottom: $b;
        border-left: $l;
        border-radius: $radius;
    }
@mixin st-txt-all($size: '12px', $line: '18px', $color: $color-txt, $weight: '400', $align: 'center', $align2: 'left') {
        font-size: $size;
        line-height: $line;
        color: $color;
        font-weight: $weight;
        text-align: $align;
        @media (min-width: $screen-sm-min) {text-align: $align2;}
    }
@mixin st-txt-size($size: '12px', $line: '18px', $weight: '400') {
        font-size: $size;
        line-height: $line;
        font-weight: $weight;
    }
@mixin st-txt-align($align: 'center', $align2: 'left') {
        text-align: $align;
        @media (min-width: $screen-sm-min) {text-align: $align2;}
    }
@mixin st-txt-link($color: $color-txt, $d:'block') {
        display: $d;
        text-decoration: none;
        color: $color;
    }
